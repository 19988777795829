.designBlock img {
    width: 100%;
}

.designBlockBottom {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}

.designBlockBottom button {
    display: flex;
    align-items: center;
    border-radius: 3px;
    padding: 8px 12px;
    gap: 6px;
    background: #97A3B8;
    border: none;
    font-size: 14px;
    cursor: pointer;
    transition: 0.3s;
}

.designBlockBottom p {
    margin-bottom: 0;
    margin-top: 0;
    color: white;
    font-weight: 600;
}

.designBlockBottom img {
    width: 20px;
}

.designBlockBottom {
    margin-top: 20px;
}

.designBlockTitle {
    color: #000;
    font-size: 16px;
    font-family: Manrope;
    font-weight: 500;
}

.designBlock {
    opacity: 0.5;
    transition: 0.3s;
}

.designBlock img{
    cursor: pointer;
}

.designBlock:hover {
    opacity: 1;
}

.adaptiveInput {
  border: 1px solid transparent;
  transition: border-color 0.3s;
  padding-top: 6px;
  padding-bottom: 6px;
  outline: none;

  border-radius: 3px;
}

.adaptiveInput:hover, .adaptiveInput:focus {
  border-color: #97A3B8;
}
